import React, { useState } from 'react'
import DropDown from '../../../shared/components/DropDown/DropDown'
import styles from './List.module.scss'
import ToggleListMode from './ToggleListMode/ToggleListMode'
import Size from '../../../common/utils/media-queries-setup'
import FilterIcon from '../../../coche/baterias/components/assets/baterias_filtro.svg'
import { t } from '../../../common/i18n'
import { OrderEntry } from '../../../coche/baterias/type/order'
import NoResultsMessage from '../../../common/components/noResultsMessage/NoResultsMessage'
import { useFilterContext } from '../../../coche/baterias/components/BateriasFilterContext'

interface ListProps<T> {
  items: T[]
  renderRow: (item: T, index: number) => JSX.Element
  isGridMode: boolean
  setIsGridMode: (isGrid: boolean) => void
  applyOrder: (order: OrderEntry) => void
  ORDER_OPTIONS: { label: string; value: string }[]
  setShowModal: (showModal: boolean) => void
  loading: boolean
}

function List<T>({
  items,
  renderRow,
  isGridMode,
  setIsGridMode,
  applyOrder,
  ORDER_OPTIONS,
  setShowModal,
  loading,
}: ListProps<T>) {
  const isMobile = Size.useMedia({
    mobile: true,
    tablet: false,
    desktop: false,
  })
  const { order, setOrder } = useFilterContext()
  const onApplyOrder = (order: never) => {
    setOrder(order)
    applyOrder(order)
  }

  return (
    <div>
      <div className={styles.list__header}>
        <div className={styles.list__viewOptions}>
          {isMobile && (
            <div
              className={styles.filterSidebar__title}
              onClick={() => setShowModal(true)}>
              <FilterIcon className={styles.filterSidebar__icon} />
              {t('baterias.filters.title')}
            </div>
          )}
          <div className={styles.list__viewToggle}>
            <ToggleListMode
              isGridMode={isGridMode}
              setIsGridMode={setIsGridMode}
            />
          </div>
          <div>
            <DropDown
              name="orderBy"
              onChange={onApplyOrder}
              options={ORDER_OPTIONS}
              defaultSelected={order}
              size={'xl'}
              variant={'sorter'}
              clearable={false}
            />
          </div>
        </div>
        <div className={styles.list__headerBorder}></div>
      </div>
      {loading ? (
        <div className={styles.loadingPlaceholder}>
          <div
            className={styles.loadingPlaceholder__item}
            style={{ width: '100%' }}></div>
          <div
            className={styles.loadingPlaceholder__item}
            style={{ width: '100%' }}></div>
          <div
            className={styles.loadingPlaceholder__item}
            style={{ width: '100%' }}></div>
          <div
            className={styles.loadingPlaceholder__item}
            style={{ width: '100%' }}></div>
          <div
            className={styles.loadingPlaceholder__item}
            style={{ width: '100%' }}></div>
        </div>
      ) : items && items.length > 0 ? (
        <div className={isGridMode ? styles.gridView : styles.listView}>
          {items.map((item, index) => renderRow(item, index))}
        </div>
      ) : (
        <NoResultsMessage />
      )}
    </div>
  )
}

export default List
