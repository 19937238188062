import React from 'react'
import { Bateria } from '../type/bateria'
import BateriaListCard from './BateriaListCard'
import List from '../../../shared/components/List/List'
import BateriaGridCard from './BateriaGridCard'
import Filters from './filters'
import { Marca } from '../type/marca'
import { FiltersParams } from '../api/getBaterias'
import StandardModal from '../../../common/components/modal/standardModal/StandardModal'
import styles from '../../../shared/components/List/List.module.scss'
import { t } from '../../../common/i18n'
import { useFilterContext } from './BateriasFilterContext'

export interface BateriaFilters {
  selectedMarcas: string[]
  selectedStartStop: string
  selectedTipo: string
  selectedAmperaje: string[]
  selectedPotencia: string[]
  selectedBornes: string
}

interface BateriaListProps {
  marcas: Marca[]
  baterias: Bateria[]
  onSelectBateria: (bateria: Bateria) => void
  isGridMode: boolean
  setIsGridMode: (isGrid: boolean) => void
  applyFilters: (filters: FiltersParams) => void
  applyOrder: (order) => void
  ORDER_OPTIONS: { label: string; value: string }[]
  loading: boolean
}

const BateriaList: React.FC<BateriaListProps> = ({
  marcas,
  baterias,
  onSelectBateria,
  isGridMode,
  setIsGridMode,
  applyFilters,
  applyOrder,
  ORDER_OPTIONS,
  loading,
}) => {
  const filterComponent = (
    <Filters
      marcas={marcas}
      loadingMarcas={loading}
      applyFilters={applyFilters}
    />
  )
  const { showModal, setShowModal } = useFilterContext()

  return (
    <>
      <List
        items={baterias}
        renderRow={(bateria, index) =>
          isGridMode ? (
            <BateriaGridCard
              key={index}
              bateria={bateria}
              onSelectBateria={onSelectBateria}
            />
          ) : (
            <BateriaListCard
              key={index}
              bateria={bateria}
              onSelectBateria={onSelectBateria}
            />
          )
        }
        isGridMode={isGridMode}
        setIsGridMode={setIsGridMode}
        applyOrder={applyOrder}
        ORDER_OPTIONS={ORDER_OPTIONS}
        setShowModal={setShowModal}
        loading={loading}
      />
      <StandardModal
        modalClass={styles.list_filter_modal}
        title={t('baterias.filters.title')}
        showModal={showModal}
        closeModal={() => setShowModal(false)}
        showHeader={false}>
        {filterComponent}
      </StandardModal>
    </>
  )
}

export default BateriaList
