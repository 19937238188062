import React, { ReactNode } from 'react'
import styles from './Badge.module.scss'

interface BadgeProps {
  icon: ReactNode
  text: string
  alt: string
}

const Badge: React.FC<BadgeProps> = ({ icon, text, alt }) => {
  return (
    <div className={styles.badge}>
      <div className={styles.badge__content}>
        <div className={styles.badge__icon} aria-hidden="true">
          {icon}
        </div>
        <span className={styles.badge__text} aria-label={alt}>
          {text}
        </span>
      </div>
    </div>
  )
}

export default Badge
