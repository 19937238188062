import { IAppState } from '../../../../common/context/context'
import { Bateria } from '../../type/bateria'
import { PaginationResult } from '../../type/pagination'

export const selectBaterias = (state: IAppState): Bateria[] | null => {
  return state.bateriasList.baterias
}
export const selectBateriasLoading = (state: IAppState): boolean => {
  return state.bateriasList.loading
}
export const selectBateriasPagination = (
  state: IAppState
): PaginationResult | null => {
  return state.bateriasList.pagination
}
