import { t } from '../../../../common/i18n'
import React from 'react'
import Pagination from 'react-js-pagination'
import styles from './MobilePagination.module.scss'

interface MobilePaginationProps {
  handlePageChange: (page: number) => void
  currentPage: number
  totalPages: number
  totalItems: number
  pageSize: number
}
const MobilePagination = ({
  handlePageChange,
  currentPage,
  totalPages,
  totalItems,
  pageSize,
}: MobilePaginationProps) => (
  <div className={styles.pagination_mobile}>
    <span>
      {t('resultados_neumaticos.pagina')} {currentPage} de {totalPages}
    </span>
    <Pagination
      activePage={currentPage}
      itemsCountPerPage={pageSize}
      totalItemsCount={totalItems}
      itemClass="item"
      hideDisabled
      hideFirstLastPages
      pageRangeDisplayed={1}
      onChange={handlePageChange}
      prevPageText={`< ${t('resultados_neumaticos.anterior')}`}
      nextPageText={`${t('resultados_neumaticos.siguiente')} >`}
    />
  </div>
)

export default MobilePagination
