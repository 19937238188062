import React from 'react'
import styles from './index.module.scss'

type FilteredInputProps = Omit<
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >,
  'size'
>
interface Props extends FilteredInputProps {
  label1: string | JSX.Element
  label2?: string | JSX.Element
  size?: '' | 'small' | 'xsmall'
  disableCheckBox?: boolean
}
const CheckBoxYellow = ({
  label1,
  label2 = '',
  size = '',
  disableCheckBox = false,
  ...props
}: Props) => {
  return (
    <label className={styles.container}>
      <input disabled={disableCheckBox} type="checkbox" {...props} />
      {size && (
        <span
          className={
            !disableCheckBox
              ? `${styles.checkmark} ${styles[`checkmark_${size}`]}`
              : `${styles.checkmark} ${styles[`checkmark_${size}`]} ${
                  styles.checkmark_disabled
                }`
          }></span>
      )}
      {!size && (
        <span
          className={
            !disableCheckBox
              ? styles.checkmark
              : `${styles.checkmark} ${styles.checkmark_disabled}`
          }></span>
      )}
      {label1}
      {label2}
    </label>
  )
}

export default CheckBoxYellow
