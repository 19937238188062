import React from 'react'
import styles from './index.module.scss'
import ArrowIcon from './assets/button_black_right_arrow.svg'

interface DefaultActionButton {
  type: 'submit' | 'reset'
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

interface CustomActionButton {
  type?: 'button'
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

type ButtonProps = {
  buttonClass?: string
  children: string | (string | JSX.Element)[] | JSX.Element
  disclaimerMessage?: string
  disabled?: boolean
} & (DefaultActionButton | CustomActionButton)

export default function Button({
  buttonClass,
  onClick,
  type,
  children,
  disclaimerMessage,
  disabled,
}: ButtonProps) {
  return (
    <>
      {disclaimerMessage && (
        <div className={styles.rodi_button_group}>
          <p className={styles.rodi_disclaimer}>{disclaimerMessage}</p>

          <button
            type={type}
            disabled={!!disabled}
            className={styles[buttonClass] || styles.rodi_button}
            onClick={onClick}>
            {children}
            {buttonClass === 'elegir_button' && (
              <ArrowIcon className={styles.arrowIcon} />
            )}
          </button>
        </div>
      )}
      {!disclaimerMessage && (
        <button
          type={type}
          disabled={!!disabled}
          className={styles[buttonClass] || styles.rodi_button}
          onClick={onClick}>
          {children}
          {buttonClass === 'elegir_button' && (
            <ArrowIcon className={styles.arrowIcon} />
          )}
        </button>
      )}
    </>
  )
}
