import React, { ChangeEvent, useEffect } from 'react'
import styles from './filters.module.scss'
import { Marca } from '../type/marca'
import { t } from '../../../common/i18n'
import WhiteBoxContainer from '../../../shared/components/WhiteBoxContainer'
import CheckBoxYellow from '../../../shared/components/Checkbox/CheckBox'
import { FiltersParams } from '../api/getBaterias'
import RadioButtonYellow from '../../../common/components/radioButtonYellow/RadioButtonYellow'
import FilterIcon from './assets/baterias_filtro.svg'
import { BornesBateria, TipoBateria } from '../type/bateria'
import CloseButton from '../../../shared/components/CloseButton/CloseButton'
import Button from '../../../shared/components/Button/Button'
import { navigate } from 'gatsby'
import { useFilterContext } from './BateriasFilterContext'
import Size from '../../../common/utils/media-queries-setup'
import ListFilterSection from '../../../shared/components/List/ListFilterSection/ListFilterSection'
import { FilterValues } from '../api/getBateriasFilterValues'
import { IAppState } from '../../../common/context/context'
import { selectBateriasMarcas } from '../store/baterias/marcasSelectors'
import { selectBateriasFilterValues } from '../store/baterias/bateriasFilterValuesSelectors'
import { marcaAction } from '../store/baterias/marcasAction'
import { bateriasFilterValuesAction } from '../store/baterias/bateriasFilterValuesAction'
import { connect } from 'react-redux'

export type FilterProps = {
  filterValues: FilterValues | null
  marcas: Marca[]
  applyFilters: (filters: FiltersParams) => void
}
const Filters: React.FC<FilterProps> = ({
  marcas,
  filterValues,
  applyFilters,
}: FilterProps) => {
  const {
    filters,
    resetFilters,
    handleFilterChange,
    handleApplyFilters,
    toggleSection,
    setShowModal,
  } = useFilterContext()
  const isMobile = Size.useMedia({
    mobile: true,
    tablet: false,
    desktop: false,
  })

  useEffect(() => {
    if (!isMobile) {
      handleApplyFilters(applyFilters)
    }
  }, [
    filters.selectedMarcas,
    filters.selectedStartStop,
    filters.selectedTipo,
    filters.selectedAmperaje,
    filters.selectedPotencia,
    filters.selectedBornes,
  ])

  return (
    <WhiteBoxContainer>
      <div className={styles.filterSidebar__header}>
        <div className={styles.filterSidebar__title}>
          <FilterIcon className={styles.filterSidebar__icon} />
          {t('baterias.filters.title')}
        </div>
        {isMobile && (
          <CloseButton
            handleOnClick={() => {
              resetFilters()
              setShowModal(false)
            }}
          />
        )}
      </div>
      <div className={styles.filterSidebar__titleBorder}></div>
      <div className={styles.filterSidebar__inputList}>
        <ListFilterSection
          titleId="baterias.filters.marca"
          expanded={filters.marcasExpanded}
          toggleSection={() => toggleSection('marcasExpanded')}>
          {marcas &&
            marcas.map((marca) => (
              <div className={styles.filterSidebar__label} key={Math.random()}>
                <CheckBoxYellow
                  size="xsmall"
                  name={marca.id_site_marca_bateria}
                  checked={filters.selectedMarcas.includes(
                    String(marca.id_site_marca_bateria)
                  )}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const value = e.target.value
                    handleFilterChange(
                      'selectedMarcas',
                      e.target.checked
                        ? [...filters.selectedMarcas, value]
                        : filters.selectedMarcas.filter((id) => id !== value)
                    )
                  }}
                  label1={marca.marca}
                  value={marca.id_site_marca_bateria}
                  key={Math.random()}
                />
              </div>
            ))}
        </ListFilterSection>
        <ListFilterSection
          titleId="baterias.filters.startStop"
          expanded={filters.startStopExpanded}
          toggleSection={() => toggleSection('startStopExpanded')}>
          <div className={styles.filterSidebar__inputList}>
            <div className={styles.filterSidebar__label} key={Math.random()}>
              <RadioButtonYellow
                variant="xsmall"
                key={Math.random()}
                label1="Sí"
                name="start_stop"
                value={true}
                checked={!!filters.selectedStartStop === true}
                onChange={(value) =>
                  handleFilterChange('selectedStartStop', value as boolean)
                }
              />
            </div>
            <div className={styles.filterSidebar__label} key={Math.random()}>
              <RadioButtonYellow
                variant="xsmall"
                key={Math.random()}
                label1="No"
                name="start_stop"
                value={false}
                checked={filters.selectedStartStop === false}
                onChange={(value) =>
                  handleFilterChange('selectedStartStop', value as boolean)
                }
              />
            </div>
          </div>
        </ListFilterSection>
        {/* TIPO_BATERIA */}
        <ListFilterSection
          titleId="baterias.filters.tipo"
          expanded={filters.tipoExpanded}
          toggleSection={() => toggleSection('tipoExpanded')}>
          <div className={styles.filterSidebar__inputList}>
            {filterValues &&
              filterValues.tipo_bateria.map((tipo) => {
                return (
                  <div
                    className={styles.filterSidebar__label}
                    key={Math.random()}>
                    <RadioButtonYellow
                      variant="xsmall"
                      label1={t(`baterias.filters.${tipo}`)}
                      name="tipo"
                      key={`tipo_${tipo}`}
                      value={tipo}
                      checked={filters.selectedTipo === tipo}
                      onChange={(value) =>
                        handleFilterChange('selectedTipo', value as TipoBateria)
                      }
                    />
                  </div>
                )
              })}
          </div>
        </ListFilterSection>

        {/* AMPERAJE */}
        <ListFilterSection
          titleId="baterias.filters.amperaje"
          expanded={filters.amperajeExpanded}
          toggleSection={() => toggleSection('amperajeExpanded')}>
          <div className={styles.filterSidebar__inputList}>
            {filterValues &&
              filterValues.amperaje.map((amperaje) => {
                return (
                  <div
                    className={styles.filterSidebar__label}
                    key={Math.random()}>
                    <CheckBoxYellow
                      size="xsmall"
                      label1={t(`baterias.filters.amperaje_selector_label`, {
                        valor: amperaje,
                      })}
                      name={`amperaje_${amperaje}`}
                      key={`amperaje_key_${amperaje}`}
                      value={amperaje}
                      checked={filters.selectedAmperaje?.includes(amperaje)}
                      onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        const value = event.target.value
                        handleFilterChange(
                          'selectedAmperaje',
                          event.target.checked
                            ? [...filters.selectedAmperaje, Number(value)]
                            : filters.selectedAmperaje.filter(
                                (item) => item !== Number(value)
                              )
                        )
                      }}
                    />
                  </div>
                )
              })}
          </div>
        </ListFilterSection>

        {/* POTENCIA_ARRANQUE */}
        <ListFilterSection
          titleId="baterias.filters.potencia"
          expanded={filters.potenciaExpanded}
          toggleSection={() => toggleSection('potenciaExpanded')}>
          <div className={styles.filterSidebar__inputList}>
            {filterValues &&
              filterValues.potencia_arranque.map((potencia) => {
                return (
                  <div
                    className={styles.filterSidebar__label}
                    key={Math.random()}>
                    <CheckBoxYellow
                      size="xsmall"
                      label1={t(`baterias.filters.potencia_selector_label`, {
                        valor: potencia,
                      })}
                      name="potencia"
                      key={`potencia_${potencia}`}
                      value={potencia}
                      checked={filters.selectedPotencia?.includes(potencia)}
                      onChange={(event) => {
                        const value = event.target.value
                        handleFilterChange(
                          'selectedPotencia',
                          event.target.checked
                            ? [...filters.selectedPotencia, Number(value)]
                            : filters.selectedPotencia.filter(
                                (item) => item !== Number(value)
                              )
                        )
                      }}
                    />
                  </div>
                )
              })}
          </div>
        </ListFilterSection>

        {/* POSICION_POSITIVO */}
        <ListFilterSection
          titleId="baterias.filters.bornes"
          expanded={filters.bornesExpanded}
          toggleSection={() => toggleSection('bornesExpanded')}>
          <div className={styles.filterSidebar__inputList}>
            {filterValues &&
              filterValues.posicion_positivo.map((value) => {
                return (
                  <div
                    className={styles.filterSidebar__label}
                    key={Math.random()}>
                    <RadioButtonYellow
                      variant="xsmall"
                      label1={t(`baterias.filters.bornes_${value}`)}
                      name="bornes"
                      key={`bornes_${value}`}
                      value={value}
                      checked={filters.selectedBornes === value}
                      onChange={(value) =>
                        handleFilterChange(
                          'selectedBornes',
                          value as BornesBateria
                        )
                      }
                    />
                  </div>
                )
              })}
          </div>
        </ListFilterSection>
      </div>

      <div className={styles.filterSidebar__buttons}>
        <Button
          onClick={() => {
            resetFilters()
            setShowModal(false)
            navigate('')
          }}
          buttonClass={'reset_button'}>
          {t('baterias.restablecer')}
        </Button>
        {isMobile && (
          <Button
            buttonClass={'elegir_button'}
            onClick={() => {
              handleApplyFilters(applyFilters)
              setShowModal(false)
              navigate('')
            }}>
            {t('baterias.filters.aplicar')}
          </Button>
        )}
      </div>
    </WhiteBoxContainer>
  )
}

const mapStateToProps = (state: IAppState) => {
  return {
    marcas: selectBateriasMarcas(state),
    filterValues: selectBateriasFilterValues(state),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getMarcas: () => dispatch(marcaAction()),
    getFilterValues: () => dispatch(bateriasFilterValuesAction()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Filters)
