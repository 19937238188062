import { IAppState } from '../../../../common/context/context'
import { FilterValues } from '../../api/getBateriasFilterValues'

export const selectBateriasFilterValues = (
  state: IAppState
): FilterValues | null => {
  return state.bateriasFilterValues.values
}
export const selectBateriasFilterValuesLoading = (
  state: IAppState
): boolean => {
  return state.bateriasFilterValues.loading
}
