import React from 'react'
import { FormattedMessage } from 'react-intl'
import styles from './listFilterSection.module.scss'
import { FaChevronUp, FaChevronDown } from 'react-icons/fa'

type FilterSectionProps = {
  titleId: string
  expanded: boolean
  toggleSection: () => void
  children: React.ReactNode
}

const ListFilterSection: React.FC<FilterSectionProps> = ({
  titleId,
  expanded,
  toggleSection,
  children,
}) => {
  return (
    <div className={styles.filterSection}>
      <div className={styles.filterSection__title} onClick={toggleSection}>
        <h4>
          <FormattedMessage id={titleId} />
          <span className={styles.arrowIcon}>
            {expanded ? (
              <FaChevronUp className={styles.icon} />
            ) : (
              <FaChevronDown className={styles.icon} />
            )}
          </span>
        </h4>
      </div>
      {expanded && (
        <div className={styles.filterSection__content}>{children}</div>
      )}
    </div>
  )
}

export default ListFilterSection
