import React from 'react'
import { t } from '../../../common/i18n'
import GarantiaIcon from './assets/garantia_icon.svg'
import Badge from '../../../shared/components/Badge/Badge'

const GarantiaBadge: React.FC = () => {
  return (
    <Badge
      icon={<GarantiaIcon />}
      text={t('baterias.garantia')}
      alt="Guarantee Icon"
    />
  )
}

export default GarantiaBadge
