import React from 'react'
import styles from './index.module.scss'
import CloseIcon from './assets/icon_close.svg'

type Props = {
  handleOnClick: () => void
}

export default function CloseButton({ handleOnClick }: Props) {
  return (
    <a onClick={handleOnClick} className={styles.modal_close}>
      <CloseIcon />
    </a>
  )
}
