import React, { useContext, useEffect, useState } from 'react'
import * as CestaActions from '../../../common/context/actions/cestaActions'
import Layout from '../../../common/components/Layout'
import { SeoData } from '../../../common/types/Seo'
import route from '../../../common/utils/route'
import { t } from '../../../common/i18n'
import { BreadCrumbItems } from '../../../common/components/breadCrumb/LocalizedBreadCrumb'
import Title from '../../../common/components/Title'
import BateriaList from './BateriaList'
import AppContext, { IAppState } from '../../../common/context/context'
import {
  selectBaterias,
  selectBateriasLoading,
  selectBateriasPagination,
} from '../store/baterias/bateriasListSelectors'
import {
  selectBateriasMarcas,
  selectBateriasMarcasLoading,
} from '../store/baterias/marcasSelectors'
import { connect } from 'react-redux'
import { Bateria } from '../type/bateria'
import { PaginationResult } from '../type/pagination'
import {
  bateriasListAction,
  ORDER_OPTIONS,
} from '../store/baterias/bateriasListAction'
import { marcaAction } from '../store/baterias/marcasAction'
import Pagination from 'react-js-pagination'
import { BateriasParams, FiltersParams } from '../api/getBaterias'
import { Marca } from '../type/marca'
import Filters from './filters'
import styles from './Baterias.module.scss'
import Size from '../../../common/utils/media-queries-setup'
import { OrderEntry } from '../type/order'
import MobilePagination from '../../../shared/components/List/Pagination/MobilePagination'
import SpinnerWithText from '../../../common/components/spinnerWithText/SpinnerWithText'
import { navigate } from 'gatsby'
import { BateriasFilterProvider } from './BateriasFilterContext'
import { DataSteps, Steps } from '../../../common/components/cesta/Steps'
import BateriasStaticContent from './BateriasStaticContent'
import { bateriasFilterValuesAction } from '../store/baterias/bateriasFilterValuesAction'
import {
  selectBateriasFilterValues,
  selectBateriasFilterValuesLoading,
} from '../store/baterias/bateriasFilterValuesSelectors'
import { FilterValues } from '../api/getBateriasFilterValues'

const PAGE_SIZE = 10

interface Props {
  data: {
    seoData: SeoData
  }
  baterias: Bateria[]
  marcas: Marca[]
  filterValues: FilterValues
  pagination: PaginationResult
  getBaterias: (data: BateriasParams) => void
  getMarcas: () => void
  getFilterValues: () => void
  loading: boolean
  loadingMarcas: boolean
}
const initalParams: BateriasParams = {
  filters: {
    marca: [],
    startStop: false,
    amperaje: [],
    posicion_positivo: '',
    potencia_arranque: [],
    tipo_bateria: '',
  },
  pagination: {
    page: 1,
    pageSize: 10,
  },
  order: {
    label: t('baterias.order.recomendado'),
    value: 'orden.asc',
    field: 'orden',
    direction: 'ASC',
  },
}

let searchParams: BateriasParams = { ...initalParams }

const breadCrumItems = [
  { display: t('bread_crumb.inicio'), link: route('index') },
  { display: t('bread_crumb.coche'), link: route('coche') },
  { display: t('bread_crumb.baterias') },
] as BreadCrumbItems
export const Baterias: React.FC<Props> = ({
  data,
  baterias,
  marcas,
  pagination,
  getBaterias,
  getMarcas,
  getFilterValues,
  loading,
}: Props) => {
  useEffect(() => {
    getFilterValues()
  }, [])
  const { dispatch } = useContext(AppContext)

  const [isGridMode, setIsGridMode] = useState(false)

  const handlePageChange = (page: number) => {
    navigate('')
    searchParams = { ...searchParams, pagination: { page, pageSize: 10 } }
    dispatch(() => getBaterias(searchParams))
  }

  const onSelectBateria = (bateria: Bateria) => {
    dispatch(
      CestaActions.addProduct(
        {
          id: bateria.id_navision,
          type: 'bateria',
        },
        'baterias'
      )
    )
    dispatch(CestaActions.setStep(Steps.TU_SOLICITUD))
    navigate(DataSteps[Steps.TU_SOLICITUD].route)
  }

  useEffect(() => {
    getBaterias(initalParams)
    getMarcas()
  }, [getBaterias, getMarcas])
  const applyFilter = (filters: FiltersParams) => {
    searchParams = { ...searchParams, filters }
    getBaterias(searchParams)
  }
  const applyOrder = (order: OrderEntry) => {
    searchParams = { ...searchParams, order }
    getBaterias(searchParams)
  }
  const isTablet = Size.useMedia({ mobile: true, tablet: true, desktop: false })
  const isMobile = Size.useMedia({
    mobile: true,
    tablet: false,
    desktop: false,
  })

  return (
    <BateriasFilterProvider>
      <Layout
        selector={false}
        seoData={data.seoData}
        breadCrumItems={breadCrumItems}>
        <div className={styles.baterias_container}>
          <Title text={t('baterias.title')} />
          <div className={styles.baterias_content}>
            {!isMobile && (
              <aside className={styles.bateriasList__filterSidebar}>
                <Filters applyFilters={applyFilter} />
              </aside>
            )}
            <main className={styles.bateriasList__main}>
              <div className={styles.bateriasList__list}>
                {loading ? (
                  <SpinnerWithText
                    text={t('baterias.buscando_baterias')}
                    className={styles.loading_container}
                  />
                ) : (
                  <BateriaList
                    marcas={marcas}
                    baterias={baterias}
                    onSelectBateria={onSelectBateria}
                    isGridMode={isGridMode || isTablet}
                    setIsGridMode={setIsGridMode}
                    applyOrder={applyOrder}
                    ORDER_OPTIONS={ORDER_OPTIONS}
                    loading={loading}
                    applyFilters={applyFilter}
                  />
                )}
              </div>
              {baterias && pagination.pages > 1 ? (
                <Size.Matcher
                  desktop={
                    <Pagination
                      activePage={searchParams.pagination.page}
                      itemsCountPerPage={PAGE_SIZE}
                      totalItemsCount={pagination.total}
                      itemClass="item"
                      hideDisabled
                      hideFirstLastPages
                      pageRangeDisplayed={5}
                      onChange={handlePageChange}
                      prevPageText={`< ${t('resultados_neumaticos.anterior')}`}
                      nextPageText={`${t('resultados_neumaticos.siguiente')} >`}
                    />
                  }
                  mobile={
                    <MobilePagination
                      handlePageChange={handlePageChange}
                      currentPage={searchParams.pagination.page}
                      totalPages={pagination.pages}
                      totalItems={pagination.total}
                      pageSize={PAGE_SIZE}
                    />
                  }
                />
              ) : null}
            </main>
          </div>
          <BateriasStaticContent />
        </div>
      </Layout>
    </BateriasFilterProvider>
  )
}
const mapStateToProps = (state: IAppState) => {
  return {
    baterias: selectBaterias(state),
    marcas: selectBateriasMarcas(state),
    filterValues: selectBateriasFilterValues(state),
    pagination: selectBateriasPagination(state),
    loading: selectBateriasLoading(state),
    loadingMarcas: selectBateriasMarcasLoading(state),
    loadingFilterValues: selectBateriasFilterValuesLoading(state),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getBaterias: (data) => dispatch(bateriasListAction(data)),
    getMarcas: () => dispatch(marcaAction()),
    getFilterValues: () => dispatch(bateriasFilterValuesAction()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Baterias)
