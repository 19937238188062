import React from 'react'
import GridIcon from './assets/grid_mode.svg'
import ListIcon from './assets/list_mode.svg'
import ActiveGridIcon from './assets/grid_mode_active.svg'
import ActiveListIcon from './assets/list_mode_active.svg'
import styles from './ToggleListMode.module.scss'

interface ToggleListModeProps {
  isGridMode: boolean
  setIsGridMode: (isGridMode: boolean) => void
}

const ToggleListMode: React.FC<ToggleListModeProps> = ({
  isGridMode,
  setIsGridMode,
}) => {
  return (
    <div className={styles.toggleListMode}>
      {isGridMode ? (
        <ListIcon
          className={styles.toggleListMode__icon}
          onClick={() => setIsGridMode(false)}
        />
      ) : (
        <ActiveListIcon
          className={styles.toggleListMode__icon}
          onClick={() => setIsGridMode(false)}
        />
      )}
      <div className={styles.toggleListMode__separator}></div>
      {isGridMode ? (
        <ActiveGridIcon
          className={styles.toggleListMode__icon}
          onClick={() => setIsGridMode(true)}
        />
      ) : (
        <GridIcon
          className={styles.toggleListMode__icon}
          onClick={() => setIsGridMode(true)}
        />
      )}
    </div>
  )
}

export default ToggleListMode
