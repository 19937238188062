import React from 'react'
import styles from './BateriaGridCard.module.scss'
import { intl, t, textWithLocale } from '../../../common/i18n'
import { Bateria } from '../type/bateria'
import Button from '../../../shared/components/Button/Button'
import GarantiaBadge from './GarantiaBadge'

interface RowProps {
  bateria: Bateria
  onSelectBateria: (bateria: Bateria) => void
}

const defaultBateriaImage =
  'https://media.rodi.es/marca/default/neumaticos/recortado/imagen-neumatico.png' // Use your actual path

interface LogoProps {
  slug: string | null
}

const Logo = ({ slug }: LogoProps) => {
  let Image
  try {
    Image = require(`../../../common/assets/images/logo_marcas/${slug}.svg`)
  } catch (e) {
    Image = require(`../../../common/assets/images/logo_baterias/no-marca.svg`)
  }

  return <Image className={styles.bateriasGridCard__logoMarca} />
}

const BateriaGridCard: React.FC<RowProps> = ({ bateria, onSelectBateria }) => {
  return (
    <article
      className={styles.bateriasGridCard}
      onClick={() => onSelectBateria(bateria)}>
      <div className={styles.bateriasGridCard__container}>
        <div className={styles.bateriasGridCard__images}>
          <Logo slug={bateria.logo} />
          <img
            className={styles.bateriasGridCard__image}
            src={bateria.urlImagenRecorte || defaultBateriaImage}
            alt={bateria[textWithLocale('nombre_producto')]}
          />
        </div>
        <div className={styles.bateriasGridCard__content}>
          {parseFloat(bateria.promo) > 0 && (
            <div className={styles.bateriasGridCard__actions__discountWrapper}>
              <div
                className={
                  styles.bateriasGridCard__actions__discountWrapper__discountTag
                }>
                {t('baterias.descuento_placeholder')}
              </div>
            </div>
          )}
          <div className={styles.bateriasGridCard__content__name}>
            {bateria[textWithLocale('nombre_producto')]}
          </div>
          <div className={styles.bateriasGridCard__actions}>
            <div className={styles.bateriasGridCard__actions__badges}>
              <GarantiaBadge />
            </div>
            {parseFloat(bateria.promo) > 0 && (
              <div
                className={
                  styles.bateriasGridCard__actions__discountWrapper__oldPrice
                }>
                {intl.formatNumber(bateria.precio, {
                  style: 'currency',
                  currency: 'EUR',
                })}
              </div>
            )}
            <div className={styles.bateriasGridCard__actions__ctaWrapper}>
              <div
                className={styles.bateriasGridCard__actions__ctaWrapper__price}>
                {intl.formatNumber(bateria.precio - parseFloat(bateria.promo), {
                  style: 'currency',
                  currency: 'EUR',
                })}
              </div>
              <Button
                buttonClass={'elegir_button'}
                onClick={() => onSelectBateria(bateria)}>
                {t('baterias.list.elegir')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </article>
  )
}

export default BateriaGridCard
