import React, { useState, createContext, useContext } from 'react'
import { BornesBateria, TipoBateria } from '../type/bateria'
import { BateriaFilters } from './BateriaList'
import { FiltersParams } from '../api/getBaterias'
import { ORDER_OPTIONS } from '../store/baterias/bateriasListAction'

type FilterBateriasParams = {
  marcasExpanded: boolean
  startStopExpanded: boolean
  tipoExpanded: boolean
  amperajeExpanded: boolean
  potenciaExpanded: boolean
  bornesExpanded: boolean
  selectedMarcas: string[]
  selectedStartStop: boolean | null
  selectedTipo: TipoBateria | null
  selectedAmperaje: number[]
  selectedPotencia: number[]
  selectedBornes: BornesBateria | null
}

type FilterContextType = {
  filters: FilterBateriasParams
  setFilters: React.Dispatch<React.SetStateAction<FilterBateriasParams>>
  handleApplyFilters: (applyFilters: (filters: FiltersParams) => void) => void
  toggleSection: (section: string) => void
  handleFilterChange: (filter: keyof BateriaFilters, value: unknown) => void
  resetFilters: () => void
  showModal: boolean
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>
  order: { label: string; value: string }
  setOrder: React.Dispatch<
    React.SetStateAction<{ label: string; value: string }>
  >
}

const initialFilters: FilterBateriasParams = {
  marcasExpanded: true,
  startStopExpanded: true,
  tipoExpanded: true,
  amperajeExpanded: true,
  potenciaExpanded: true,
  bornesExpanded: true,
  selectedMarcas: [] as string[],
  selectedStartStop: null,
  selectedTipo: null,
  selectedAmperaje: [],
  selectedPotencia: [],
  selectedBornes: null,
}

const initialShowModal = false
const initialOrder = ORDER_OPTIONS[0]

const BateriasFilterContext = createContext<FilterContextType>({
  filters: initialFilters,
  setFilters: () => {},
  handleApplyFilters: () => {},
  toggleSection: () => {},
  handleFilterChange: () => {},
  resetFilters: () => {},
  showModal: initialShowModal,
  setShowModal: () => {},
  order: initialOrder,
  setOrder: () => {},
})

export const useFilterContext = () => useContext(BateriasFilterContext)

export const BateriasFilterProvider: React.FC = ({ children }) => {
  const [filters, setFilters] = useState<FilterBateriasParams>(initialFilters)
  const [showModal, setShowModal] = useState<boolean>(initialShowModal)
  const [order, setOrder] = useState<{ label: string; value: string }>(
    initialOrder
  )

  const handleApplyFilters = (
    applyFilters: (filters: FiltersParams) => void
  ) => {
    applyFilters({
      marca: filters.selectedMarcas,
      startStop: filters.selectedStartStop,
      tipo_bateria: filters.selectedTipo,
      amperaje: filters.selectedAmperaje,
      potencia_arranque: filters.selectedPotencia,
      posicion_positivo: filters.selectedBornes,
    })
  }

  const toggleSection = (section: string) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [section]: !prevFilters[section],
    }))
  }

  const resetFilters = () => {
    setFilters({
      ...filters,
      selectedMarcas: [],
      selectedStartStop: null,
      selectedTipo: null,
      selectedAmperaje: [],
      selectedPotencia: [],
      selectedBornes: null,
    })
  }

  const handleFilterChange = (filter: keyof BateriaFilters, value: unknown) => {
    console.log('HandleFilterChange')
    console.log(filter, value)
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filter]: value,
    }))
  }

  return (
    <BateriasFilterContext.Provider
      value={{
        filters,
        setFilters,
        handleApplyFilters,
        toggleSection,
        handleFilterChange,
        resetFilters,
        showModal,
        setShowModal,
        setOrder,
        order,
      }}>
      {children}
    </BateriasFilterContext.Provider>
  )
}
