import React from 'react'
import { locale } from '../../../common/i18n'
import styles from './BateriasStaticContent.module.scss'
const ContentEs: React.FC = () => {
  return (
    <div className={styles.wrapper}>
      <h2 className="ficha-subtitulo">¿QUÉ ES UNA BATERÍA?</h2>
      <p className="ficha-texto">
        La principal función de la batería consiste en{' '}
        <strong>
          aportar la energía necesaria para la puesta en marcha del motor de
          arranque de nuestro vehículo
        </strong>
        , que después continúa recargándose por medio del alternador.
      </p>
      <p className="ficha-texto">
        Además, sirve también de{' '}
        <strong>
          apoyo al alternador cuando éste no puede suministrar toda la corriente
          que requieren otros consumidores eléctricos
        </strong>{' '}
        del automóvil, como por ejemplo los accesorios de confort o seguridad:
        radio, GPS, cierre centralizado o sistema de iluminación.
      </p>
      <p className="ficha-texto">
        <br />
      </p>
      <h2 className="ficha-subtitulo">
        ¿QUÉ PROVOCA EL DETERIORO DE LA BATERÍA?
      </h2>
      <p className="ficha-texto">
        Las baterías <strong>se descargan con el paso del tiempo</strong>. Sin
        embargo, existen{' '}
        <strong>varios factores que provocan un deterioro</strong> prematuro:
      </p>
      <ul className="ficha-lista-2">
        <li>
          <p className="ficha-texto">
            Una<strong> temperatura extrema</strong> puede afectar negativamente
            la batería de nuestro vehículo. En invierno hay que prestarle una
            mayor atención, ya que las bajas temperaturas hacen que los procesos
            químicos las ralenticen, provocando una mayor resistencia a la
            fricción del motor.{' '}
          </p>
        </li>
        <li>
          <p className="ficha-texto">
            Un <strong>fallo en el alternador</strong> o en el proceso de carga
            puede hacer que la batería se descargue sin que nos demos cuenta,
            así que es imprescindible tener este elemento en buen estado.{' '}
          </p>
        </li>
      </ul>
      <p className="ficha-texto">
        <br />
      </p>
      <h2 className="ficha-subtitulo">¿CUÁNDO DEBO CAMBIAR LA BATERÍA?</h2>
      <p className="ficha-texto">
        La <strong>duración media</strong> de la batería de un vehículo{' '}
        <strong>ronda lo 4 años</strong>. En RODI MOTOR SERVICES recomendamos
        revisar la batería cada año, ya que{' '}
        <strong>
          el mantenimiento preventivo permite alargar su vida útil
        </strong>
        .
      </p>
      <p className="">
        <br />
      </p>
      <h2 className="ficha-subtitulo">
        TIPOS DE BATERÍA DISPONIBLES EN RODI MOTOR SERVICES
      </h2>
      <p className="ficha-texto">
        En RODI MOTOR SERVICES disponemos de tres tipos de baterías:
      </p>
      <ul className="ficha-lista-2">
        <li>
          <p className="ficha-texto">
            <strong>Baterías de calcio. </strong>Son muy comunes. Están
            fabricadas con una aleación de calcio que evita que la batería
            pierda menos fluido, gracias a lo cual alargan su vida útil y se
            descargan más lentamente.{' '}
          </p>
        </li>
        <li>
          <p className="ficha-texto">
            <strong>Baterías de gel. </strong>La principal función de la batería
            consiste en aportar la energía necesaria para la puesta en marcha
            del motor de arranque de nuestro vehículo, que después continúa
            recargándose por medio del alternador. Además, sirve también de
            apoyo al alternador cuando éste no puede suministrar toda la
            corriente que requieren otros consumidores eléctricos del automóvil,
            como por ejemplo los accesorios de confort o seguridad: radio, GPS,
            cierre centralizado o sistema de iluminación.{' '}
          </p>
        </li>
        <li>
          <p className="ficha-texto">
            <strong>Baterías Start/Stop. </strong>Las baterías de los coches
            modernos van equipadas con un sistema conocido como Start/Stop
            (sistema automático de parada y arranque del motor) que permite
            reducir tanto el consumo como las emisiones del vehículo.{' '}
          </p>
        </li>
      </ul>
    </div>
  )
}

const ContentCa: React.FC = () => {
  return (
    <div className={styles.wrapper}>
      <ul className="ficha-lista-1">
        <li>
          <h2 className="ficha-subtitulo">QUÈ ÉS UNA BATERIA?</h2>
          <p className="ficha-texto">
            La funció principal de la bateria consisteix a{' '}
            <strong>
              aportar l’energia necessària per a la posada en marxa del motor
              d’engegada del vehicle
            </strong>
            , que després continua recarregant-se mitjançant l’alternador.
          </p>
          <p className="ficha-texto">
            A més a més, també fa de{' '}
            <strong>
              suport a l’alternador quan aquest no pot subministrar tot el
              corrent que necessiten els altres consumidors elèctrics
            </strong>{' '}
            de l’automòbil, com ara els accessoris de confort o de seguretat: la
            ràdio, el GPS, el tancament centralitzat o el sistema
            d’il·luminació.
          </p>
        </li>
        <li>
          <h2 className="ficha-subtitulo">
            QUÈ PROVOCA EL DETERIORAMENT DE LA BATERIA?
          </h2>
          <p className="ficha-texto">
            Les bateries <strong>es descarreguen amb el pas del temps</strong>.
            No obstant, existeixen{' '}
            <strong>diversos factors que provoquen un deteriorament</strong>{' '}
            prematur:
          </p>
          <ul className="ficha-lista-2">
            <li>
              <p className="ficha-texto">
                Una<strong> temperatura extrema</strong> pot afectar
                negativament la bateria del nostre vehicle. A l'hivern cal
                prestar més atenció, ja que les baixes temperatures fan que els
                processos químics les ralenteixin, provocant una major
                resistència a la fricció del motor.
              </p>
            </li>
            <li>
              <p className="ficha-texto">
                Una <strong>fallada en l'alternador</strong> o en el procés de
                càrrega pot fer que la bateria es descarregui sense que ens
                adonem, així que és imprescindible tenir aquest element en bon
                estat.
              </p>
            </li>
          </ul>

          <h2 className="ficha-subtitulo">QUAN HE DE CANVIAR LA BATERIA?</h2>
          <p className="ficha-texto">
            La <strong>durada mitjana</strong> de la bateria d’un vehicle gira
            al voltant dels <strong>4 anys</strong>. A RODI MOTOR SERVICES
            recomanem revisar la bateria cada any, ja que el{' '}
            <strong>
              manteniment preventiu permet allargar la seva vida útil
            </strong>
            .
          </p>

          <h2 className="ficha-subtitulo">
            TIPUS DE BATERIES DISPONIBLES A RODI MOTOR SERVICES
          </h2>
          <p className="ficha-texto">
            A RODI MOTOR SERVICES disposem de tres tipus de bateries:
          </p>
          <ul className="ficha-lista-2">
            <li>
              <p className="ficha-texto">
                <strong>Bateries de calci. </strong>Són molt comunes. Estan
                fabricades amb un aliatge de calci que evita que la bateria
                perdi menys fluid, cosa que permet allargar-ne la vida útil i
                que es descarreguin més lentament.
              </p>
            </li>
            <li>
              <p className="ficha-texto">
                <strong>Bateries de gel. </strong>La funció principal de la
                bateria consisteix a aportar l’energia necessària per a la
                posada en marxa del motor d’engegada del vehicle, que després
                continua recarregant-se mitjançant l’alternador. A més a més,
                també fa de suport a l’alternador quan aquest no pot
                subministrar tot el corrent que necessiten els altres
                consumidors elèctrics de l’automòbil, com ara els accessoris de
                confort o de seguretat: la ràdio, el GPS, el tancament
                centralitzat o el sistema d’il·luminació.
              </p>
            </li>
            <li>
              <p className="ficha-texto">
                <strong>Bateries Start/Stop. </strong>Les bateries dels cotxes
                moderns estan equipades amb un sistema anomenat Start/Stop
                (sistema automàtic d’aturada i engegada del motor) que permet
                reduir tant el consum com les emissions del vehicle.
              </p>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  )
}
const BateriasStaticContent = () => {
  switch (locale) {
    case 'es':
      return <ContentEs />
    case 'ca':
      return <ContentCa />
    default:
      return null
  }
}

export default BateriasStaticContent
